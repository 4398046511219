import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221113 - Minimalism?" />
      <h2 className="font-title-sub text-center">Minimalism?</h2>
      <p>I’m kind of getting bored to hear the word “minimalism” these days in Japan. People easily connect it to disposing as many things as possible or people living in a simple room. The image is not wrong in some point, but I feel the minimalism is also wasted like a trend, and becoming a minimalist is getting people’s purpose rather than using the concept as the way to make the life enhanced.</p>
      <p>The limitation of owning stuffs itself is a good idea, and I totally agree with that. However, for that process of selecting items, you also have to realize you’ll never know the value unless you own them. </p>
      <p>The another aspect that I have a doubt about many minimalists is how much time they waste time on screen. Even if you lead a life with limited things physically, mentally they spend time surrounded in much information, just scrolling or tapping on SNS. That’s not minimal!</p>
      <p>Maybe it’s not only me thinking about the same things like above for mass produced minimalists. Recently I read 2 books, which makes me think about what the true minimalism is. </p>
      <h3 className="font-title-sub text-center">Lessons From Madame Chic</h3>
      <div className="text-center pb-3">
        <a className="image" href="//af.moshimo.com/af/c/click?a_id=3406872&amp;p_id=170&amp;pc_id=185&amp;pl_id=4062&amp;url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB007Z4SQZU" rel="nofollow" referrerpolicy="no-referrer-when-downgrade"><img src="https://images-fe.ssl-images-amazon.com/images/I/51+cvsTmoaL._SL500_.jpg" alt="" /></a><img src="//i.moshimo.com/af/i/impression?a_id=3406872&amp;p_id=170&amp;pc_id=185&amp;pl_id=4062" alt="" width="1" height="1" />
      </div>
      <p>You can learn some tips from french life for how to improve your life, based on experience of the American author, who one day started to stay at Madame Chic’s house. Everything is a simple little step, but it will lead your daily life more meaningful.</p>
      <h3 className="font-title-sub text-center">Digital Minimalism: Choosing a Focused Life in a Noisy World</h3>
      <div className="text-center pb-3">
        <a className="image" href="//af.moshimo.com/af/c/click?a_id=3406872&amp;p_id=170&amp;pc_id=185&amp;pl_id=4062&amp;url=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB07DBRBP7G" rel="nofollow" referrerpolicy="no-referrer-when-downgrade"><img src="https://images-fe.ssl-images-amazon.com/images/I/51JsmX0uryL._SL500_.jpg" alt="" /></a><img src="//i.moshimo.com/af/i/impression?a_id=3406872&amp;p_id=170&amp;pc_id=185&amp;pl_id=4062" alt="" width="1" height="1" />
      </div>
      <p>Digital devices are useful but on the other hand, these take our time away that we can spend on other productive purposes. If you tend to spend much time on smartphone, I recommend to read this book. Sometimes we need to go back to analog.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
